import { render, staticRenderFns } from "./xiaoyuan.vue?vue&type=template&id=3a7a527e&scoped=true&"
import script from "./xiaoyuan.vue?vue&type=script&lang=js&"
export * from "./xiaoyuan.vue?vue&type=script&lang=js&"
import style0 from "./xiaoyuan.vue?vue&type=style&index=0&id=3a7a527e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a7a527e",
  null
  
)

export default component.exports